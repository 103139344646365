
import './App.css';
import { Routes, Route, Outlet, Link,BrowserRouter } from "react-router-dom";
import Home from './Pages/Home/Home';
import RecipeDetails from './Pages/RecipeDetails/RecipeDetails';
import CategoryList from './Pages/CategoryList/CategoryList';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path ="/RecipeDetails/:id" element={<RecipeDetails/>}/>
        <Route path ="/CategoryList/:name" element={<CategoryList/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
