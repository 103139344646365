import React from 'react'
import './Category.css'
import { useNavigate } from "react-router-dom";

function Category() {
    const navigate =useNavigate();

  const navigateToCategory = (name) => {
    
    navigate(`/CategoryList/${name}`)
  };

  return (
    <div className="Category">
        <h4>Search by Category</h4>
        <p>Search recipes by selecting a category from the below options</p>
        <div className="AllCategoryList">
            <div className="CategoryList"  onClick={()=>navigateToCategory("Starter")}   >
                <div className="CategoryListImage">
                    <img src="./category_icon.png" alt="category-icon"/>
                </div>
                <p>STARTERS</p>
            </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Breakfast")}>
            <div className="CategoryListImage">
                <img src="./breakfast 1.png" alt="category-icon"/>
            </div>
            <p>BREAKFAST</p>
        </div>
        <div className="CategoryList" onClick={()=>navigateToCategory("Chicken")}>
            <div className="CategoryListImage">
                <img src="./turkey 1.png" alt="category-icon"/>
            </div>
            <p>CHICKEN</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Beef")}>
            <div className="CategoryListImage">
                <img src="./meat 1.png" alt="category-icon"/>
            </div>
            <p>BEEF</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Vegetarian")}>
            <div className="CategoryListImage">
                <img src="./broccoli 1.png" alt="category-icon"/>
            </div>
            <p>VEGETARIAN</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Lamb")}>
            <div className="CategoryListImage">
                <img src="./lamb 1.png" alt="category-icon"/>
            </div>
            <p>LAMB</p>
        </div>
        <div className="CategoryList" onClick={()=>navigateToCategory("Seafood")}>
            <div className="CategoryListImage">
                <img src="./seafood 1.png" alt="category-icon"/>
            </div>
            <p>SEAFOOD</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Pork")}>
            <div className="CategoryListImage">
                <img src="./chop 1.png" alt="category-icon"/>
            </div>
            <p>PORK</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Goat")}>
            <div className="CategoryListImage">
                <img src="./goat 1.png" alt="category-icon"/>
            </div>
            <p>GOAT</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Pasta")}>
            <div className="CategoryListImage">
                <img src="./spaguetti 1.png" alt="category-icon"/>
            </div>
            <p>PASTAS</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Vegan")}>
            <div className="CategoryListImage">
                <img src="./vegan 1.png" alt="category-icon"/>
            </div>
            <p>VEGAN</p>
        </div>
        <div className="CategoryList"  onClick={()=>navigateToCategory("Dessert")}>
            <div className="CategoryListImage">
                <img src="./ice-cream 1.png" alt="category-icon"/>
            </div>
            <p>DESERTS</p>
        </div>
        </div>
    
    </div>
  )
}

export default Category