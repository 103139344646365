import React from 'react'
import './Categorylist.css'
import ItemCard from '../ItemCard/ItemCard'

function Categorylist({meals,name}) {
  return (
    <div className="Categorylist">
    <h4>{name}</h4>
    <div className="AllItemCards" >
        {meals.map((meal,key)=>{
            return <ItemCard meal={meal} key={key}/>
        })}
        
        
    </div>
</div>
  )
}

export default Categorylist