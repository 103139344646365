import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Category from "../../Components/Category/Category";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import PopularRecipe from "../../Components/PopularRecipe/PopularRecipe";
import RandomRecipe from "../../Components/RandomRecipe/RandomRecipe";
import ItemCard from '../../Components/ItemCard/ItemCard';

export default function Home(){

    const [meals, setMeals] = useState([]); // Holds all the meal data
    const [searchQuery, setSearchQuery] = useState(''); // Holds the search input

      // Fetch meals data from API on mount
  useEffect(() => {
    async function fetchMeals() {
      const response = await axios.get(`https://www.themealdb.com/api/json/v1/1/search.php?f=${searchQuery}`); // Fetch meals starting with 'a'
      setMeals(response.data.meals || []);
    }
    fetchMeals();
  }, [searchQuery]);

   // Filter the meals based on the search query
//    const filteredMeals = meals.filter(meal =>
//     meal.strMeal.toLowerCase().startsWith(searchQuery.toLowerCase())
//   );
    return(
        <>
        <div>
            <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                {/* <div className="ItemCards">
                {filteredMeals.length > 0 ? ( filteredMeals.map((meal) => <ItemCard key={meal.idMeal} meal={meal} />)) : (<p>No meals found matching the search</p>)}
                </div> */}
        </div>
    
        <PopularRecipe/>
        <Category/>
        <RandomRecipe/>
        <Footer/>
            
        </>
    )
}