import React from 'react'
import './ItemCard.css'
import { useNavigate } from "react-router-dom";

function ItemCard({meal}) {

  const navigate =useNavigate();

  const navigateToItem = (id) => {
    // Navigate to an external URL with the meal's id as a query parameter
    // window.location.href = `http://localhost:3001/RecipeDetails?id=${id}`;
    navigate(`/RecipeDetails/${id}`)
  };
  
  return (
        <div className="ItemCard"  onClick={()=>navigateToItem(meal.idMeal)} key={meal.idMeal}>
            <div className="ItemPicture">
                <img src = {meal.strMealThumb} alt="ItemImage"></img>
            </div>
            <p>{meal.strMeal.length > 15 ? `${meal.strMeal.slice(0, 15)}...` : meal.strMeal}</p>
        </div>
  )
}

export default ItemCard