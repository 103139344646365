
import { useParams } from 'react-router-dom'
import React, { useState,useEffect } from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import RecipeDescription from '../../Components/RecipeDescription/RecipeDescription'
import Ingredients from '../../Components/Ingredients/Ingredients'
import axios from "axios";

function RecipeDetails() {
  const { id } = useParams(); // Extract id from URL
  const [recipe,setRecipe]=useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // Call the API when the component mounts or when recipeId changes

      fetchRecipe();
      console.log('TESTING');
    
  }, []);
  
  async function fetchRecipe(){
    setLoader(true)
    const response = await axios.get(`https://themealdb.com/api/json/v1/1/lookup.php?i=${id}`);
    console.log(response.data.meals);
    setRecipe(response.data.meals[0])
    setLoader(false)
  }
 
  return (
    <>
            <Header/>
            {recipe && <RecipeDescription recipe={recipe} loader={loader}/>}
            {recipe && <Ingredients recipe={recipe} /> }
            <Footer/>
            
            
        </>
  )
}

export default RecipeDetails