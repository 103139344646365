import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className="Footer">
        <p>Designed & Developed by Christy Maria James</p>
    </div>
  )
}

export default Footer