import React, { useEffect, useState } from "react";
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Categorylist from '../../Components/Categorylist/Categorylist'
import axios from "axios";
import { useParams } from 'react-router-dom'

function CategoryList() {
  const { name } = useParams(); // Extract id from URL

  const [meals,setMeals] = useState([]);

  useEffect(() =>{
   fetchdata();
  },[]);
  
async function fetchdata(){
  const response = await axios.get(`https://themealdb.com/api/json/v1/1/filter.php?c=${name}`);
  console.log(response.data.meals);
  setMeals(response.data.meals)
}

  return (
    <>
    <Header/>
    <Categorylist meals={meals} name={name}/>
    <Footer/>
    
</>
  )
}

export default CategoryList