import React, {useState, useEffect} from 'react'
import './Ingredients.css'

function Ingredients({recipe}) {
  const [ingredients, setIngredients] = useState([]);
  //   if(!recipe) {
  //       return <p>No recipe data available</p>;
  //    } 
  //   console.log(recipe)
  //   // Array to hold the JSX elements for ingredients
  // const ingredientsList = [];

  
  // for (let i = 1; i <= 20; i++) {
  //   const ingredientKey = `strIngredient${i}`; 
  //   const ingredient = recipe[ingredientKey]; 

    
  //   if (ingredient && ingredient.trim() !== "") {
  //     ingredientsList.push(
  
  //     );
  //   }
  // }

  useEffect(() => {
    setIngredientsData();
  },[]);

  const setIngredientsData = () => {
    let ingredientArray = [];
    for (let i = 1; i <= 20; i++) {
      if(recipe[`strIngredient${i}`] !== "") {
        ingredientArray.push({  
          name : recipe[`strIngredient${i}`],
          measure : recipe[`strMeasure${i}`],
        })
      }
    }

    console.log('Ingredients', ingredientArray);
    setIngredients(ingredientArray)
  }

  return (
    <div className="Ingredients">
        <h4>Ingredients Required</h4>
        <div className="IngredientsItems">
          {ingredients?.map((ingredient) => (
            <div className="IngredientItemImage">
              <img src={`https://www.themealdb.com/images/ingredients/${ingredient.name}.png`} alt='IngredientImage' />
              <h6 className='ingredientName' >{ingredient.measure ? ingredient.measure : ""} {ingredient.name}</h6>
            </div>
          ))}
          
        </div>
    </div>
  

  )
}

export default Ingredients