import React, { useState,useEffect } from 'react'
import './PopularRecipe.css'

import axios from "axios";
import ItemCard from '../ItemCard/ItemCard';


function PopularRecipe() {


  const[popularrecipe,setpopularrecipe] = useState([]);

  useEffect(() => {
    setpopularrecipeData();
  },[]);

   const  setpopularrecipeData = async() => {
    let recipeArray = [];
    for (let i = 1; i <= 5; i++) {
      const response = await axios.get("https://www.themealdb.com/api/json/v1/1/random.php");
      // console.log(response.data.meals[0].strMealThumb);
      recipeArray.push(response.data.meals[0])
      
      
    }
    setpopularrecipe(recipeArray);
  }

  return (
    <div className="popularRecipe">
        <h4>Popular Recipes of the Day</h4>
        <div className="popularRecipeBox"  >
          {popularrecipe.map((recipe,index)=>(
            <ItemCard meal={recipe}/>
          ))}
        </div>  
    </div>
  )
}

export default PopularRecipe