import React from 'react'
import './Header.css'
import { useNavigate } from 'react-router-dom'

function Header({ searchQuery, setSearchQuery }) {

  const navigate = useNavigate();

  return (
    <div className="header">
        <div className="logo" onClick={() => navigate('/')}>
          <img src="/logo.png" alt="logo"/>
        </div>
        {/* <div className="search">
          <input type='text' value ={searchQuery}  placeholder='Search your Favourite dish here'/>
        </div> */}
    </div>
  )
}

export default Header