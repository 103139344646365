import React, { useState,useEffect } from 'react'
import './RecipeDescription.css'




function RecipeDescription({recipe,loader}) {

  console.log(recipe)
  const[RecipeDescriptionData,setRecipeDescriptionData] = useState();

  useEffect(()=>{
    setDescriptionSplitData();
  },[])

  function setDescriptionSplitData(){
    let description = recipe.strInstructions;
    let descriptionSplit  =description.split("\r\n");
    console.log(descriptionSplit)
    setRecipeDescriptionData(descriptionSplit)
  }
  
  return (
    <>
    {recipe &&
    <div className="RecipeDescription">
      <div className="RecipeImage">
          <img src={recipe.strMealThumb} alt='Food'/>
      </div>
        
      <div className="RecipeDetails">
          <h3>{recipe.strMeal}</h3>
          <h5>How to Cook ?</h5>
          {RecipeDescriptionData?.map((item) => (
            <p>{item}</p>
          ))}
          
      </div>
    </div> }
   </>
  )
}

export default RecipeDescription