import React ,{ useEffect, useState } from 'react'
import './RandomRecipe.css'
import axios from "axios";

function RandomRecipe() {
  const[randomRecipe,setRandomRecipe] = useState([]);
  const[showrandomRecipe,setShowRandomRecipe] = useState(false);
  
   
 async function fetchdata(){
   const response = await axios.get("https://www.themealdb.com/api/json/v1/1/random.php");
   console.log(response.data.meals[0].strMealThumb);
   setRandomRecipe(response.data.meals[0])
 }

 function NewRandomRecipe(){
  fetchdata();
  setShowRandomRecipe(true);
 }

  return (
   
   <div className="RandomRecipe">
      <div className="RandomRecipeText">
        <h2>Still Confused</h2>
        <h2>on What to Cook?</h2>
        <p>We will pick one for you !</p>
      </div>
      <div className="RandomRecipeBox" onClick={NewRandomRecipe} >
        <div className='RandomRecipeBoxContent'>
          {showrandomRecipe && randomRecipe?
          
          (<>
          <img className="RandomRecipeImage"src={randomRecipe.strMealThumb} alt='randomRecipe'/>
          
          <p>{randomRecipe.strMeal}</p></>):

          (<><img src="./Questionmark.png" alt='Question Mark'/>
          <p>Click to find a Recipe</p></>)
          
          }
        </div>
      </div>
   </div>
  )
}

export default RandomRecipe